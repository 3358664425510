.modal {
	height: 100vh;
	width: 100vw;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	transition: 0.5s;

	&_close {
		background-color: transparent;
		border: none;
	}
}

.modal.active {
	opacity: 1;
	pointer-events: all;
}

.modal_content {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 30px;
	border-radius: 12px;
	background-color: white;
	width: 600px;
	height: 300px;
	transform: scale(0.5);
	transition: 0.4s all;
}

.modal_content.active {
	transform: scale(1);
}

.modal_wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 30px;
}