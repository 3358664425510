.range-slider .range-slider__thumb {
  position: absolute;
  z-index: 3;
  top: 50%;
  width: 1rem;
  height: 1rem;
  transform: translate(-50%, -50%);
  border: 1px solid var(--bs-info);
  border-radius: 50%;
  background: var(--bs-primary);
}

.range-slider input[type="range"] {
  -webkit-appearance: none;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: transparent;
  display: none;
}

.range-slider {
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: 0.5rem;
  background: var(--bs-light);
  border-radius: var(--bs-border-radius);
}

.range-slider .range-slider__range {
  position: absolute;
  z-index: 1;
  transform: translate(0, -50%);
  top: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid var(--bs-info);
  background: var(--bs-primary);
  border-radius: var(--bs-border-radius);
}

.range-slider.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
  height: 0;
  border: unset;
}