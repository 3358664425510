.editor-container {
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	gap: .5rem;

	@media screen and (max-width: 800px) {
		flex-direction: column-reverse;
	}
}

.editor-toolbar {
	display: flex;
	flex-direction: column;
	gap: .5rem;
	
	@media screen and (max-width: 800px) {
		flex-direction: row;
	}
}

.content-editable {
	height: 16.3rem;
	width: 100%;
	background-color: white;
	border-radius: 10px;
	padding: .3rem;
	border: 2px solid #dee2e6;
	margin-top: .6rem;
	overflow: auto;
  touch-action: pan-y;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;

	&:focus {
		border-color: #008CBC;
		outline: none;
	}
}

.custom-paragraph {
	width: 100%;
	margin: 0;
	padding: .2rem .2rem;
	height: auto;
	user-modify: read-only;
	-moz-user-modify: read-only;
	-webkit-user-modify: read-only;
	cursor: pointer;

		&:hover, &:focus {
			background-color: #008dbc25;
			border-radius: 5px;
		}
}

.custom-paragraph[data-focus="true"] {
	background-color: #008dbc25;
	border-radius: 5px;
}

.custom-paragraph[data-tooltip]::after {
	font-size: .8rem;
	font-weight: 500;
  content: attr(data-tooltip);
  background-color: #008CBC;
  color: #fff;
  padding: 0 .2rem;
  border-radius: 5px;
	margin-left: .6em;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.custom-paragraph[data-tooltip]:hover::after,
.custom-paragraph[data-focus="true"][data-tooltip]:after {
  opacity: 1;
}
