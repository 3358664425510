.input-project-name {
	width: 100%;
	font-size: clamp(1.2em, 2.5vw, 1.8em);
	font-weight: 500;
	border: none;
	border-radius: 5px;
	outline: none;
	outline-color: transparent;
	padding-left: .3em;
	background-color: #FFFFFF;
	transition: background-color .2s linear, outline-color .2s linear;

	&:focus {
		outline-style: solid;
		outline-width: 2px;
		outline-color: #008CBC;
	}
	
	&:disabled {
		background-color: transparent;
		backdrop-filter: none;
		color: #212529;
		user-select: none;
	}

	&::placeholder {
		color: #212529;
	}
}