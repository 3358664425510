.upload-container {
	border: 2px dashed #dee2e6;
	border-radius: 1em;
	padding: 1em;
	height: 8rem;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	gap: 1rem;
	overflow-x: auto;
	transition: border-color .1s ease-in-out;
	cursor: pointer;

	&_disabled {
		border-style: solid;
	}

	&_active {
		border-color: #008CBC;
	}

	&__info {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: .5em;
		width: 8rem;
		text-align: center;
		flex: 0 0 auto;
		user-select: none;

		&_drop {
			width: 8.5rem;
		}

		&-icon {
			color: #212529;
			width: clamp(2em, 3vw, 2.5em);
			height: clamp(2em, 3vw, 2.5em);
			transition: color .1s ease-in-out;
		}

		&-text {
			display: -webkit-box;
			color: #212529;
			font-size: .9em;
			line-height: 1.2em;
			font-weight: 500;
			margin: 0;
			white-space: normal;
			max-width: 100%;
			transition: color .1s ease-in-out;
			overflow: hidden;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 2;
		}

		&-clear {
			position: absolute;
			right: 0;
			top: 0;
			width: 1.4rem;
			height: 1.4rem;
			cursor: pointer;
			transition: color .2s ease-in-out;

			&:hover {
				color: #953553;
			}
		}
	}
}

.upload:disabled + .upload-container {
	cursor: default;
}