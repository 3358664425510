$primary: #8fd7f0;
$secondary: #212529;
$dark: #212529;
$light: #FFF5FF;
$danger: #953553;
$warning: #FFC35B;
$success: #42c742;
$info: #008CBC;
$blue: #E1F7FF;

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');

body {
    padding: 0;
    margin: 0;
    font-family: Poppins, sans-serif;
}

.btn-width {
    width: 280px;
    font-size: 18px;
}

.btn-render-width {
    width: 240px;
    font-size: 14px;
}

.btn_lng_select {
    background: transparent;
    border: none;
}

.btn_lng_select:hover {
    background-color: rgb(247, 246, 246);
}

.page_background {
    background: url('../assets/wavesOpacity.svg');
    background-repeat: no-repeat;
    background-size: 100% 12rem;
}

.ya-share2__title {
    @extend .text-light;
}

.ya-share2__link {
    @extend .btn;
    @extend .btn-dark;
}

.ya-share2__container_size_m .ya-share2__badge .ya-share2__icon {
    height: 1.5rem !important;
    width: 1rem !important; 
    background-size: 0;
}

.ya-share2__badge {
    background: none !important;
    display: inline-block !important;
}

.ya-share2__icon {
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: center;
    box-sizing: border-box;
    background-size: 1rem 1rem;
    box-sizing: border-box;
}

.btn-outline-info:hover {
    color: #FFF5FF
}

.btn-file-upload {
    background-color: #6abad4;
    border-radius: 22px;
}

.btn-file-upload:hover {
    background-color: #8fd7f0;
}

.faq_btn {
    position: relative;
    display: flex;
    align-self: center;
    padding: 0 9px 0 9px;
    color: #4a525a;
}

.faq_hover {
    position: absolute;
    margin-left: 90%;
    z-index: 12;
    width: 240px;
    text-align: left;
    padding: 6px;
    background-color: #cbecf7;
    border: 1px solid #8fd7f0;
    border-radius: 6px;
    animation: fadeInCard 0.5s ease-in-out 0s 1 normal forwards;
}

.tg_button {
    color: #008CBC;
}

.project_faq {
    position: absolute;
    font-size: 1em;
    margin: 0;
    top: -2em;
    right: 0;
    color: #000000A6;
    user-select: none;
}

.nav_dropdown_menu {
    background-color: #cbecf7;
    border: 1px solid #8fd7f0;
    border-radius: 6px;
}

.pass_input {
    position: relative;
}

.eye_button {
    position: absolute;
    left: 94%;
    top: 33%;
    width: 24px;
    height: 24px;
    cursor: pointer;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: scale(0.6);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.nav_animate {
    animation: fadeIn 0.75s ease-in-out 0s 1 normal;
}

@keyframes fadeInCard {
	0% {
		opacity: 0;
		transform: scale(0.6);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

.card_animate {
    animation: fadeInCard 0.75s ease-in-out 0s 1 normal forwards;
}

.anim_content_first {
    -webkit-animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

.anim_content_second {
    -webkit-animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.3s;
            -webkit-animation-delay: 0.3s;
}

.anim_content_third {
    -webkit-animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-bottom 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
            animation-delay: 0.5s;
            -webkit-animation-delay: 0.5s;
}

@-webkit-keyframes scale-up-bottom {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }
  @keyframes scale-up-bottom {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transform-origin: 50% 100%;
              transform-origin: 50% 100%;
    }
  }



  .anim_audio_first {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  .anim_audio_second {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 0.3s;
            -webkit-animation-delay: 0.3s;
  }

  .anim_audio_third {
    -webkit-animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation-delay: 0.5s;
            -webkit-animation-delay: 0.5s;
  }

  @-webkit-keyframes slide-right {
    0% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes slide-right {
    0% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
              -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
              -webkit-transform: scale(1);
              transform: scale(1);
    }
  }


@media screen and ( width <= 400px ) {
    .faq_hover {
        margin-left: 18%;
    }
    .project_faq {
        text-align: center;
    }
    .carousel-text {
        font-size: 12px;
    }
    .carousel-img {
        margin-bottom: 120px;
    }
    .project_buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project_share_buttons {
        margin-top: 12px;
    }
    .eye_button {
        left: 85%;
    }
}

@media screen and ( width <= 800px ) {
    .faq_hover {
        margin-left: 22%;
    }
    .project_faq {
        text-align: center;
        
        &_second {
            top: -3em;
        }
    }
    .carousel-text {
        font-size: 12px;
    }
    .carousel-img {
        margin-bottom: 120px;
    }
    .project_buttons {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .project_share_buttons {
        margin-top: 12px;
    }
    .eye_button {
        left: 85%;
    }
    .page_background {
        background-size: 100% 10rem;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    margin: 0 5px;
    background: $blue;
}

::-webkit-scrollbar-thumb {
    background: $primary;
    border-radius: 10px;
    cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
    background: $info;
}