.video {
	&-container {
		position: relative;
		width: 100%;
		height: 100%;
		background: #000 center center / cover no-repeat;
		overflow: hidden;
		z-index: 1;
	}

	&_vertical {
		video::-webkit-media-text-track-display {
			font-size: 50%;
		}
	}

	&-overlay{
		padding-top: 500px !important;
		height: 0;
		max-width: 100%;
		width: 100%;
		pointer-events: none;
	}

	&-player {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: auto;
		z-index: 10;
	}

	&-text {
		color: #fff;
		background-color: #0000009d;
		position: absolute;
		text-align: center;
		user-select: none;
		left: 0;
		right: 0;
		bottom: 15%;
		padding: .2em;
		z-index: 15;
	}
}